import { CanActivateFn, Router } from '@angular/router';
import { UserContextService } from '../../../context/user-context/user-context.service';
import { inject } from '@angular/core';
import { isNullish } from '../../../shared/helpers/ts.helpers';
import { SubscriptionContextService } from '../../../context/subscription-context/subscription-context.service';
import { StorageContextService } from '../../../context/storage-context/storage-context.service';

export const ProgramLandingGuard: CanActivateFn = async route => {
  const userContext = inject(UserContextService);
  const subscriptionsContext = inject(SubscriptionContextService);
  const storageContext = inject(StorageContextService);
  const router = inject(Router);

  const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');
  const programParams = route.queryParamMap.get('program-params');

  if (programParams) {
    storageContext.setItem('programConfiguration', programParams);
  }

  if (apiKey) {
    storageContext.setItem('access_token', apiKey.replace(/\s/g, '+'));
  }

  const token = storageContext.getItem('access_token');

  if (!token) {
    return true;
  }

  const loadUser = async () => {
    try {
      return await userContext.getOrLoad();
    } catch (_) {
      return null;
    }
  };

  const loadSubscriptions = async () => {
    try {
      return (await subscriptionsContext.getOrLoad()) ?? [];
    } catch (_) {
      return [];
    }
  };

  const [user, subscriptions] = await Promise.all([loadUser(), loadSubscriptions()]);

  if (isNullish(user) || (!user.has_access && subscriptions.length === 0)) {
    return true;
  }

  if (user.has_access || subscriptions.some(s => s.status === 'active')) {
    const backUrl = 'app/home';
    await router.navigateByUrl(backUrl);
    return false;
  }
  return true;
};
