<div class="wrapper">
  <div class="content">
    <dashboard-header [componentType]="'home'" />

    <router-outlet />
  </div>

  <div class="other">
    <dashboard-footer />
  </div>

  <!--  <div-->
  <!--    class="loading"-->
  <!--    [hidden]="!loading"></div>-->
</div>
