@if (loading || localeService.reloading() || globalLoader.isLoading()) {
  <div
    class="loading"
    [class.!bg-white]="globalLoader.isHideContent()">
    <app-loading />
    <app-loading-logo />
  </div>
}

<div
  #dialogContainer
  style="height: 0">
  <app-sprite-svg></app-sprite-svg>
</div>

<div
  #notificationContainer
  style="height: 0">
</div>

<router-outlet [class.opacity-0]="globalLoader.isHideContent()"></router-outlet>

@if (!cookieNoticeDisabled) {
  <app-cookie-notice
    (disableCookieNotice)="disableCookieNotice()"
    [class.opacity-0]="globalLoader.isHideContent()" />
}
