import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { AuthorizationGuard } from './guards/authorization-guard/authorization.guard';
import { PricesResolver } from '../resolvers/prices-resolver/prices-resolve.service';

const authRoutes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [AuthorizationGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'sign-up',
      },
      {
        path: 'sign-in',
        loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
      },
      {
        path: 'sign-in/:type',
        loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
      },
      {
        path: 'sign-up/:type',
        loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule),
        resolve: {
          prices: PricesResolver,
        },
      },
      {
        path: 'sign-up',
        loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule),
        resolve: {
          prices: PricesResolver,
        },
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
      },
      {
        path: 'sign-up-no-trial',
        loadChildren: () =>
          import('./sign-up-no-trial/sign-up-no-trial.module').then(m => m.SignUpNoTrialModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
  providers: [PricesResolver, AuthorizationGuard],
})
export class AuthRoutingModule {}
